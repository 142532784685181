import { AiOutlineLogin } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function ServiceProviderLogin({viewStateFn}) {

  const navigate = useNavigate();

  const createServiceProvider = () => {
    localStorage.setItem(document.location.hostname + '.view', 'S');
    viewStateFn('S')
    navigate('/serviceProviders')
  }

  return (
      <div className="mx-auto max-w-screen-xl text-center ">
        <h1 className="mb-4 text-xl font-bold tracking-tight leading-none text-gray-800">Are you a Business or organization?</h1>
        <h2>Share business details on JustWhere community.</h2>
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
          <p className="py-3 flex justify-center">
            <button className=" button-style" id="profile" onClick={createServiceProvider}>
              <AiOutlineLogin className="inline-icon" />
              Create Profile
            </button></p>
        </div>
      </div>
  )
};

