//param title, close and save handles, locales
import { AiOutlineProfile } from "react-icons/ai";
import { getInfo, GetAccount, UpdateAccount, GetAccounts, UpdateServiceProvider, GetServiceProvider } from "../utilities/calls";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { address_locales, service_provider_profile, countryLocaleList } from "../utilities/templates";
import { renderField } from "../utilities/functions";
import CreateCustomTag from "./CreateCustomTag";
import CopyToClipboard from "./CopyToClipboard";


//getaccounts will include service providers map get the details from there and also update the map for changes....
export default function ServiceProviderProfileForm() {
  const [sent, setSent] = useState(true);
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [account, setAccount] = useState({});

  const [selectedCountry, setSelectedCountry] = useState(
    Intl.NumberFormat().resolvedOptions().locale
  );
  const [updatedaddress, updateAddress] = useState({
    name: "",
    description: "",
    categories: "",
    website: "",
    logourl: "",
    apiconnect: "",
    apidomain: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    email: "",
    phone: "",
    addressee: ""
  });
  const [customTags, setCustomTags] = useState([])
  const { spid } = useParams();

  const location = useLocation(); // Get the tags from the state param
  const isReadOnly = location.state.readOnly;
  const GetSP = (id) => {
    id = id || spid;
    let tags = {};
    GetServiceProvider(id).then((resp) => {
      updateAddress({
        name: resp.tags.name?.Value, description: resp.tags.description?.Value, categories: resp.tags.categories?.Value, website: resp.tags.website?.Value, logourl: resp.tags.logourl?.Value, apiconnect: resp.tags.apiconnect?.Value, apidomain: resp.tags.apidomain?.Value, street: resp.tags.street?.Value,
        addressee: resp.tags.addressee?.Value,
        city: resp.tags.city?.Value,
        state: resp.tags.state?.Value,
        zipCode: resp.tags.zipCode?.Value,
        country: resp.tags.country?.Value,
        phone: resp.tags.phone?.Value,
        email: resp.tags.email?.Value
      })
      var keys = Object.keys(updatedaddress).map((key) => { return key.toLowerCase() });

      Object.keys(resp.tags).map((tag) => {
        var name = resp.tags[tag].Name;
        if (keys.indexOf(name.toLowerCase()) === -1)
          tags[name] = resp.tags[tag];
        return;
      })
      setCustomTags(tags);
    }).catch((err) => { console.log("Error fetching service provider details", err) })
  }

  useEffect(() => {
    GetAccounts().then((response) => {
      console.log('GETAccounts response', response)
      Object.keys(response).forEach((key, index) => {
        GetAccount(key).then((accDetails) => {
          setAccount(accDetails);
        }).catch((error) => {
          console.log("Error in Get Account details", error);
        });
      })
    }).catch((error) => {
      console.error("Error fetching accounts:", error);
      setShowAlert(true);
      setAlertText("Error fetching data from server. Please try again!");
    });
    if (spid.localeCompare("00000000-0000-0000-0000-000000000000") !== 0)
      GetSP();
  }, []);


  const getMergedData = () => {
    var mergedData = {
      tags: {
        name: {
          Name: "name",
          Value: updatedaddress?.name,
          Private: false,
          Required: false,
          Editable: false,
        },
        description: {
          Name: "description",
          Value: updatedaddress?.description,
          Private: false,
          Required: false,
          Editable: false
        },
        categories: {
          Name: "categories",
          Value: updatedaddress?.categories,
          Private: false,
          Required: false,
          Editable: false
        },
        website: {
          Name: "website",
          Value: updatedaddress?.website,
          Private: false,
          Required: false,
          Editable: false
        },
        logourl: {
          Name: "logourl",
          Value: updatedaddress?.logourl,
          Private: false,
          Required: false,
          Editable: false
        },
        apidomain: {
          Name: "apidomain",
          Value: updatedaddress?.apidomain,
          Private: false,
          Required: false,
          Editable: false
        },
        addressee: {
          Name: "addressee",
          Value: updatedaddress?.addressee,
          Private: false,
          Required: false,
          Editable: false
        },
        street: {
          Name: "street",
          Value: updatedaddress?.street,
          Private: false,
          Required: false,
          Editable: false,
        },
        city: {
          Name: "city",
          Value: updatedaddress?.city,
          Private: false,
          Required: false,
          Editable: false
        },
        state: {
          Name: "state",
          Value: updatedaddress?.state,
          Private: false,
          Required: false,
          Editable: false
        },
        zipCode: {
          Name: "zipCode",
          Value: updatedaddress?.zipCode,
          Private: false,
          Required: false,
          Editable: false
        },
        email: {
          Name: "email",
          Value: updatedaddress?.email,
          Private: false,
          Required: false,
          Editable: false
        },
        phone: {
          Name: "phone",
          Value: updatedaddress?.phone,
          Private: false,
          Required: false,
          Editable: false
        },
        __SYS_JW_TOS__: {
          Name: "Terms of Service",
          Value: true,
          Private: true,
          Required: true,
          Editable: false,
        }
      }
    }
    return mergedData;
  }

  const addCustomTag = (tag) => {
    console.log(tag)
    var tagname = tag.Name;
    var tags = customTags;
    tags[tagname] = tag;
    setCustomTags((prevValues) => ({
      ...prevValues, [tagname]: tag
    }))
    setSent(false);
  }

  const removeCustomTag = (tagName) => {
    console.log(tagName)
    setCustomTags((prevValues) => { delete prevValues[tagName]; return prevValues; })
    setSent(false);
  }

  const saveSP = (mergedData) => {
    UpdateServiceProvider(spid, mergedData)
      .then((response) => {
        setShowAlert(true);
        setAlertText("Service Provider added!");
        setSent(true);
        GetSP();
      })
      .catch((error) => {
        console.error("Error UPDATING service provider details:", error);
        setShowAlert(true);
        setAlertText("Save Service Provider profile failed! Sorry!");
      });
  }

  const addServiceProviderAndSaveSP = (mergedData) => {
    const individualid = getInfo("IndividualID");
    let serviceProviders = account.ServiceProviders || {};
    let beneficiary = account.Beneficiaries || {};
    serviceProviders["00000000-0000-0000-0000-000000000000"] = true;

    UpdateAccount(account.id, individualid, {}, serviceProviders, beneficiary).then((response) => {
      let oldSps = Object.keys(account.ServiceProviders);
      let spid = Object.keys(response.ServiceProviders).find((el) => oldSps.indexOf(el) === -1);
      UpdateServiceProvider(spid, mergedData).then((response) => {
        setShowAlert(true);
        setAlertText("Service Provider added!");
        setSent(true);
        GetSP(spid);
      })
        .catch((error) => {
          console.error("Error UPDATING service provider details:", error);
          setShowAlert(true);
          setAlertText("Save Service Provider profile failed! Sorry!");
        });
    }).catch((error) => { console.log("Error in updateAccount", error) })
  }

  const processForm = () => {
    var mergedData = getMergedData();
    mergedData.tags = { ...mergedData.tags, ...customTags }
    console.log(mergedData, customTags);
    if (spid.localeCompare("00000000-0000-0000-0000-000000000000") !== 0)
      saveSP(mergedData);
    else {
      addServiceProviderAndSaveSP(mergedData)
    }
  };

  const handleInputChange = (e) => {
    if (e.target.name === "country") {
      handleCountryChange(e);
      return;
    }
    const { name, value } = e.target;
    updateAddress((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setSent(false);
  };

  const handleCountryChange = (e) => {
    const countryVal = countryLocaleList.find(
      (o) => o.name === e.target.value
    ).value;
    updateAddress((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
    setSent(false);

    setSelectedCountry(countryVal);
  };

  function handleAccordionClick(ev) {
    let dv, icon;
    if (ev.target.dataset && ev.target.dataset.accordionTarget) {
      dv = document.getElementById(ev.target.dataset.accordionTarget);
      icon = ev.target.querySelector("svg");
    } else {
      const button = ev.target.closest("button");
      dv = document.getElementById(button.dataset.accordionTarget);
      icon = button.querySelector("svg");
    }
    if (dv.classList.contains("hidden")) {
      dv.classList.add("block");
      dv.classList.remove("hidden");
      icon.classList.remove("rotate-180");
    } else {
      dv.classList.add("hidden");
      dv.classList.remove("block");
      icon.classList.add("rotate-180");
    }
  }


  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="form-label">
            <AiOutlineProfile className="inline-icon" />
            Service Provider Details
          </h1>
          {spid && spid.localeCompare("00000000-0000-0000-0000-000000000000") !== 0 ? <><h1 id="spId">{spid}</h1><CopyToClipboard elId="spId" /></> : <></>}
          {spid && spid.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? <h4 className="text-sm py-2">Your business details will be visible to the JustWhere community.</h4> : <></>}
        </div>

        <div id="accordion-collapse" data-accordion="collapse" className="p-5">
          <h2 id="accordion-collapse-heading-1">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
              <span>Services Details </span>
              <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
            <div class="p-5 border border-grey-700">
              <fieldset disabled={isReadOnly ? "disabled" : ""}>

                {service_provider_profile.map((element) => {
                  return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                })
                }
              </fieldset>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-2">
            <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 gap-3" data-accordion-target="accordion-collapse-body-2" onClick={handleAccordionClick}>
              <span>Address Details</span>
              <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-2" class="hidden" aria-labelledby="accordion-collapse-heading-2">
            <div class="p-5 border border-grey-700">
              <fieldset disabled={isReadOnly ? "disabled" : ""}>

                {address_locales[selectedCountry]
                  ? address_locales[selectedCountry].map((element) => {
                    return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                  })
                  : address_locales["default"].map((element) => {
                    return renderField(element.label, element.id, element.type, updatedaddress[element.id], handleInputChange, element.options);
                  })}
              </fieldset>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-3">
            <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border gap-3" data-accordion-target="accordion-collapse-body-3" onClick={handleAccordionClick}>
              <span>More Details </span>
              <svg data-accordion-icon class="rotate-180 w-3 h-3 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
              </svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-3" className="hidden" aria-labelledby="accordion-collapse-heading-3">
            <div class="p-5 border border-grey-700">

              <fieldset disabled={isReadOnly ? "disabled" : ""}>

                <div className="py-5"><CreateCustomTag isReadOnly={isReadOnly} saveFn={addCustomTag} savedTags={customTags} deleteFn={removeCustomTag} /></div>
              </fieldset>
            </div>
          </div>

        </div>
        {
          spid.localeCompare("00000000-0000-0000-0000-000000000000") === 0 &&

          <div className="p-5">

            <a href="#/policies/serviceProviderPolicy" style={{ display: sent ? "none" : "block" }} target="_blank" rel="noopener noreferrer" className="px-2"> By clicking on ADD you agree to the JustWhere <span className="text-blue-400">{" "}Terms of Service for Service Provider</span></a>

          </div>

        }


        <section className="flex flex-start gap-2 py-2">
          <button style={{ width: "fit-content", display: sent ? "none" : "block", }} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-address-add-submit" form="form-address-new" className="col-span-2 px-2 button-style positive-action">
            {spid.localeCompare("00000000-0000-0000-0000-000000000000") !== 0 ? "UPDATE" : "ADD"}
          </button>

          {!sent ? (
            <Link className="col-span-2" to={location.state.previousPage}>
              <button className="  button-style">Cancel</button>
            </Link>
          ) : (
            <Link className="col-span-2" to={location.state.previousPage}>
              <button className=" button-style">Close</button>
            </Link>
          )}
          <div class=" col-span-8 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }}>
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
      </div>
    </div >
  );
}


