import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  AiOutlineShareAlt,
  AiOutlineProfile,
  AiOutlineImport,
  AiOutlineMail,
} from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import {
  DeleteRequest,
  ConvertUnixTimestampToDateString,
  GetRequestDetails,
  GetSentRequestsFrom,
  GetReceivedRequestsFor,
  getInfo,
  FetchIndividual,
  GetBeneficiary,
  GetServiceProvider
} from "../utilities/calls";
import { Link, useNavigate } from "react-router-dom";
import "./Requests.css";
import * as dayjs from "dayjs";
import * as relativetime from "dayjs/plugin/relativeTime";
import Login from "./Login";
import CopyToClipboard from "./CopyToClipboard";
import { isMobile } from "../utilities/functions";
import { RequestStatus } from "../utilities/functions";


dayjs.extend(relativetime);

const Requests = () => {
  const navigate = useNavigate();
  const [individual, setIndividual] = useState({});
  const [sentRequestsArray, setSentRequestsArray] = useState([]);
  const [receivedRequestsArray, setReceivedRequestsArray] = useState([]);
  const [selectedSPBen, setSelectedSPBen] = useState([]);
  const [showSPBenData, setShowSPBenData] = useState();
  const [isSP, setIsSP] = useState();

  const [userId, setUserId] = useState();
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();

  const [benList, setBenList] = useState({});
  const [spList, setSpList] = useState({});

  useLayoutEffect(() => {
    setUserId(getInfo("UserID"));
    window.setTimeout(() => window.scrollTo(localStorage.getItem(document.location.hostname + ".requests.x"), localStorage.getItem(document.location.hostname + ".requests.y")),500) ;
  }, []);

  const DeleteRequestWith = (request) => {
    DeleteRequest(request.id)
      .then((response) => {
        // Set the retrieved individual in the state
        //window.alert("Deleted Request " + request.id);
        setShowAlert(true);
        setAlertText("Deleted request " + request.id);
        const timer = setTimeout(() => {
          setShowAlert(false);
        }, 2000);
        setSentRequestsArray([]);
        GetSentRequests();
        })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual");
      });
  };

  const GetSentRequests = () => {
    const individualid = getInfo("IndividualID");
    GetSentRequestsFrom(individualid, true)
      .then((response) => {
        setSentRequestsArray(response);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual, please try again!");
      });


  }
  
  

  const sendReminderEmail = (req) => {
    GetRequestDetails(req.id).then((request) => {

    const origin = window.location.origin;
    const to =  encodeURIComponent(request.toIndividualEmail);
    const subject = encodeURIComponent("Reminder: Share content on JustWhere ("+ origin + ")")
    const body =  encodeURIComponent(
        "Hi,\nI have sent a request to share content on JustWhere, can you please respond to the request " + origin + "/#requests?\n\n" +
          request.tags?.note?.Value +
          "\n\nI'm using JustWhere("+ origin +") to maintain my content. It will be great if you share content on JustWhere("+ origin +")"
      );
    var link = isMobile() ? "mailto:"+ to + "?subject=" + subject + "&body=" + body :
      "https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=" + to + "&su=" + subject + "&body=" + body;      
    //window.open(link, "_blank");
    const a = document.createElement("a"); a.setAttribute('href', link); a.setAttribute('target', '_blank'); a.click(); 
    }).catch((err) => {
      console.log('error fetching request details');
    })
  };

  const viewBenSP = (isSP) => {
    console.log('viewBenSP', isSP, selectedSPBen)
    setIsSP(isSP);
    setShowSPBenData(true);
  }

  const viewBen = (e) => {
    const button = e.target.closest("button");
    GetBeneficiary(button.id).then((resp) => {
      setSelectedSPBen(Object.values(resp.tags));
    });
    viewBenSP(false);
  }
  
  const viewSP = (e) => {
    const button = e.target.closest("button");
    GetServiceProvider(button.id).then((resp) => {
      setSelectedSPBen(Object.values(resp.tags));
    }); 
    viewBenSP(true);
  }

  const saveCurrentState = (e) => {
    console.log(e)
    let x = e.screenX;  // Horizontal
    let y = e.screenY;  // Vertical
    localStorage.setItem(document.location.hostname + ".requests.x", x);
    localStorage.setItem(document.location.hostname + ".requests.y",y);
  } 
  

  useEffect(() => {

    sessionStorage.removeItem('pg_redirect');

    setShowSPBenData(false);

    // Fetch sent requests
    const individualid = getInfo("IndividualID");
    FetchIndividual(individualid)
      .then((individualData) => {
        // Set the retrieved individual in the state
        setIndividual(individualData);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual");
      });
      GetSentRequests();
    // Fetch received requests
    GetReceivedRequestsFor(individualid, true)
      .then((response) => {
        setReceivedRequestsArray(response);
      })
      .catch((error) => {
        console.error("Error fetching individual:", error);
        setShowAlert(true);
        setAlertText("Error fetching individual, please try again!");
      });
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  useEffect(() => {
    let benIds = [], spIds = [];
    receivedRequestsArray.map((req) => {
    if(req.fromBeneficiaryID && req.fromBeneficiaryID !== "" && !benIds.includes(req.fromBeneficiaryID)) {
      benIds.push(req.fromBeneficiaryID);
      GetBeneficiary(req.fromBeneficiaryID).then((el) => setBenList((benList) => ({...benList, [req.fromBeneficiaryID] : el}))).catch((err) => console.log(err))
    }
    if(req.fromServiceProviderID && req.fromServiceProviderID !== "" && !spIds.includes(req.fromServiceProviderID)){
      spIds.push(req.fromServiceProviderID)
      GetServiceProvider(req.fromServiceProviderID).then((el) => setSpList((spList) => ({...spList, [req.fromServiceProviderID] : el}))).catch((err) => console.log(err))
    }
  })
  }, [receivedRequestsArray])

  const processRequest = (request, requestFrom) => {
    GetRequestDetails(request.id).then((reqDetails) => {
      navigate('/process-request', {state: {request: reqDetails, addresses: individual.addresses, previousPage: "/requests", requestFrom : requestFrom}})
    }).catch((err) => {
      console.log('Error in getting request details', err)
    })
  }

  return userId ? (
    <div>
      {individual.id ? (

        <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
          <h1 className="py-5 bold-text-input">Content Sharing Requests</h1>
          <Link className=" " to={{ pathname: "/CreateAddressRequest" }}>
            <button className="button-style hover:bg-555">
              <AiOutlineImport className="inline-icon" />
              Request Content
            </button>
          </Link>
          <div id="accordion-collapse" data-accordion="collapse" className="py-5">
            <h2 id="accordion-collapse-heading-1">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-1" onClick={handleAccordionClick}>
                <span>Received Requests {receivedRequestsArray?.length || 0}</span>
                <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".requests.accordion.accordion-collapse-body-1") == null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" : "w-3 h-3 shrink-0" } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
              </button>
            </h2>
            <div id="accordion-collapse-body-1" class={localStorage.getItem(document.location.hostname + ".requests.accordion.accordion-collapse-body-1") == null ? "hidden" : localStorage.getItem(document.location.hostname + ".requests.accordion.accordion-collapse-body-1")} aria-labelledby="accordion-collapse-heading-1">
              <div class="p-5 border border-gray-700">
                {receivedRequestsArray === undefined || receivedRequestsArray.length === 0 ? <></> :
                  <ul class="w-full divide-y divide-gray-700">
                    {receivedRequestsArray.sort((a,b) => { return b.modifiedAt - a.modifiedAt} ).map((Arequest) => {
                      return (
                        <li class="py-2">
                           
                          <div className=" grid grid-cols-1 md:grid-cols-2 items-center justify-between">
                            <div className=" order-2 md:order-1 flex-1"><p class="py-1 text-sm md:font-medium"><p>ID:</p><span className="text-gray-400 text-xs md:text-sm font-extralight"id={"req_" +Arequest.id }>{Arequest.id}</span><CopyToClipboard elId={"req_" +Arequest.id }/></p></div>
                            <div class="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:justify-end rtl:space-x-reverse">
                              <RequestStatus status={Arequest.status} />
                              <span className="py-1 text-sm md:font-medium" title={ConvertUnixTimestampToDateString(Arequest.modifiedAt)}>
                                {dayjs().to(ConvertUnixTimestampToDateString(Arequest.modifiedAt))}
                              </span>
                            </div>
                          </div>
                            <div className="grid grid-cols-1 py-3">
                              <p class=" text-sm md:font-medium">From: </p>
                              <p className="text-sm text-gray-400">{Arequest.fromIndividualEmail}</p>
                               {Arequest.fromBeneficiaryID !== "" ?  
                                                         <><p><Link to={`/AddBeneficiaryProfile/${Arequest.fromBeneficiaryID}`} state={{readOnly: true, showPrivate: false, previousPage: '/requests'}} >
                                                         <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="View Beneficiary" id={Arequest.fromBeneficiaryID} onClick={viewBen}>
                                    <AiOutlineProfile title="View Beneficiary" className="inline-icon" />
                                    {benList[Arequest.fromBeneficiaryID]?.tags?.name?.Value + " (Beneficiary)"}
                                  </button></Link></p>
                                  <p class="text-sm text-gray-400">
                       <span id={"ben_guid_" +Arequest.fromBeneficiaryID }>{Arequest.fromBeneficiaryID}</span><CopyToClipboard elId={"ben_guid_" +Arequest.fromBeneficiaryID }/>
                             </p></>: <></>}{Arequest.fromServiceProviderID !== "" ?                          <><p><Link to={`/AddServiceProviderProfile/${Arequest.fromServiceProviderID}`} state={{readOnly: true, showPrivate: false, previousPage: '/requests'}}>
<button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="View Service Profile" id={Arequest.fromServiceProviderID} onClick={viewSP}>
                                    <AiOutlineProfile title="View Service Provider" className="inline-icon" />
                                   {spList[Arequest.fromServiceProviderID]?.tags?.name?.Value + " (Service Provider)"}
                                  </button></Link></p><p class="text-sm text-gray-400">
                       <span id={"sp_guid_" +Arequest.fromServiceProviderID }>{Arequest.fromServiceProviderID}</span><CopyToClipboard elId={"sp_guid_" +Arequest.fromServiceProviderID }/>
                             </p></> : <></> }
                             
                             <blockquote class="py-3 text-xs italic font-semibold text-white">
  <p className="truncate">{Arequest.tags?.note?.Value} </p> </blockquote>
                              
                              {Arequest.status === "NEW" ? (
                                <Link className="py-3">
                                  <button className= "button-style " title="Share Content" onClick={(e) => {processRequest(Arequest, {"ben": benList[Arequest.fromBeneficiaryID]?.tags?.name?.Value? benList[Arequest.fromBeneficiaryID]?.tags?.name?.Value + " (Beneficiary) " : "", "sp": spList[Arequest.fromServiceProviderID]?.tags?.name?.Value? spList[Arequest.fromServiceProviderID]?.tags?.name?.Value + " (Service Provider)" : "", "ind" : Arequest.fromIndividualEmail}); saveCurrentState(e);}}>
                                    <AiOutlineShareAlt title="Share Content" className="inline-icon" />
                                    Share Content
                                  </button>
                                </Link>
                              ) : (
                                <Link className="py-3" >
                                  <button className="button-style" title="Edit Shared Content" onClick={(e) => {processRequest(Arequest, {"ben": benList[Arequest.fromBeneficiaryID]?.tags?.name?.Value? benList[Arequest.fromBeneficiaryID]?.tags?.name?.Value + " (Beneficiary) " : "", "sp": spList[Arequest.fromServiceProviderID]?.tags?.name?.Value? spList[Arequest.fromServiceProviderID]?.tags?.name?.Value + " (Service Provider)" : "", "ind" : Arequest.fromIndividualEmail}); saveCurrentState(e);}}>
                                    <AiOutlineImport title="Edit Shared Content" className="inline-icon" />
                                    Edit Shared Content
                                  </button>
                                </Link>
                              )}
                            </div>
                            
                        </li>)
                    })}
                  </ul>
                }
              </div>
            </div>
            <h2 id="accordion-collapse-heading-2">
              <button type="button" class="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-gray-700 text-gray-400 gap-3" data-accordion-target="accordion-collapse-body-2" onClick={handleAccordionClick}>
                <span>Requests Sent  {sentRequestsArray?.length || 0}</span>
                <svg data-accordion-icon class={localStorage.getItem(document.location.hostname + ".requests.accordion.accordion-collapse-body-2") === null || "hidden" ? "rotate-180 w-3 h-3 shrink-0" :  "w-3 h-3 shrink-0" } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
              </button>
            </h2>
            <div id="accordion-collapse-body-2" class={localStorage.getItem(document.location.hostname + ".requests.accordion.accordion-collapse-body-2") === null ? "hidden" : localStorage.getItem(document.location.hostname + ".requests.accordion.accordion-collapse-body-2")} aria-labelledby="accordion-collapse-heading-2">
              <div class="p-5 border border-gray-700 ">
                {sentRequestsArray === undefined || sentRequestsArray.length === 0 ? <></> :
                  <ul class="w-full divide-y divide-gray-700">
                    {sentRequestsArray.sort((a, b) => { return b.modifiedAt - a.modifiedAt } ).map((Arequest) => {
                      return (
                        <li class="py-2 ">
                           <div className=" grid grid-cols-1 md:grid-cols-2 items-center justify-between">
                            <div className="order-2 md:order-1 flex-1"><p class="py-1 text-sm md:font-medium"><p>ID:</p><span className="text-gray-400 text-xs md:text-sm font-extralight"id={"req_" +Arequest.id }>{Arequest.id}</span><CopyToClipboard elId={"req_" +Arequest.id }/></p></div>
                            <div class="order-1 md:order-2 grid grid-cols-1  md:justify-items-end md:justify-end rtl:space-x-reverse">
                              <RequestStatus status={Arequest.status} />
                              <span className="py-1 text-sm md:font-medium" title={ConvertUnixTimestampToDateString(Arequest.modifiedAt)}>
                                {dayjs().to(ConvertUnixTimestampToDateString(Arequest.modifiedAt))}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="w-full grid grid-cols-2 justify-around self-start">
                              <p class="py-1 text-sm md:font-medium">To: {Arequest.toIndividualEmail}</p>
                                <blockquote class="py-3 text-xs italic font-semibold text-white">
                                  <p className="truncate">{Arequest.tags?.note?.Value} 
                                </p> </blockquote>

                              {Arequest.status === "ACCEPTED" ? (
                                Arequest.requestType === "SHARE_ADDRESS" ? 
                                <Link className="py-3" to={`/viewrequestaddress-details/${Arequest.id}`} state={{ previousPage: "/requests" }}>
                                  <button className="button-style" title="Show Content" onClick={saveCurrentState}>
                                    <AiOutlineProfile title="Show Content" className="inline-icon" />
                                    Show Content
                                  </button>
                                </Link>
                                : 
                                <Link className="py-3" to={`/viewsecurecontent/${Arequest.id}`} state={{ previousPage: "/requests" }}>
                                  <button className="button-style" title="Show Content" onClick={(e) => { saveCurrentState(e)}}>
                                    <AiOutlineProfile title="Show Content" className="inline-icon" />
                                    Show Content
                                  </button>
                                </Link>
                              ) : dayjs().diff(dayjs(ConvertUnixTimestampToDateString(Arequest.modifiedAt)), "hour") > 24 ? (
                                <Link className="py-3">
                                <button formMethod="dialog" formTarget="top" id="sendReminder" onClick={(e) => { sendReminderEmail(Arequest); saveCurrentState(e);}}
                                  className="button-style"
                                >
                                  <AiOutlineMail title="Send Reminder" className="inline-icon" />
                                  Send Reminder
                                </button></Link>
                              ) : (<p> </p>
                              )}
                              <div class="py-3 grid grid-cols-1 justify-items-end justify-end  rtl:space-x-reverse">
                              <button formMethod="dialog" formTarget="top" id="deleterequest" onClick={() => DeleteRequestWith(Arequest)} className="bg-slate-333 py-1 hover:bg-555">
                                <BsFillTrash3Fill title="Delete" className="inline-icon" />
                              </button>
                            </div>
                            </div>
                            </div>

                            

                            
                        </li>)
                    })}
                  </ul>
                 }   </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {showAlert ? (
            <div
              class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
              role="alert"
              style={{ display: showAlert ? "block" : "none" }}
            >
              <span class="text-sm">{alertText} </span>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      )}
    </div>
  ) : (
    <Login />
  );
};


function handleAccordionClick(ev){
  let dv, icon;
  if(ev.target.dataset && ev.target.dataset.accordionTarget){
    dv = document.getElementById(ev.target.dataset.accordionTarget);
    icon = ev.target.querySelector("svg");
  } else {
    const button = ev.target.closest("button");
    dv = document.getElementById(button.dataset.accordionTarget);
    icon = button.querySelector("svg");
  }
  if(dv.classList.contains("hidden")) {
    localStorage.setItem(document.location.hostname + '.requests.accordion.' + dv.id, 'block' )

    dv.classList.add("block"); 
    dv.classList.remove("hidden");
    icon.classList.remove("rotate-180");
  } else {
    localStorage.setItem(document.location.hostname + '.requests.accordion.' + dv.id, 'hidden' )

    dv.classList.add("hidden");
    dv.classList.remove("block");
    icon.classList.add("rotate-180");
  }
}

export default Requests;
