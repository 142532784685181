// src/components/AddressDetails.js
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GetSecuredContent, GetSecuredContents, UpdateRequest } from "../utilities/calls";
import "./ProcessRequest.css";
import { AiOutlineImport, AiOutlineProfile } from "react-icons/ai";
import { contentTypeList } from "../utilities/templates";

const ProcessRequest = () => {
  const {
    state: { request: Arequest, addresses: addressList, previousPage : previousPage, requestFrom : requestFrom },
  } = useLocation({ state: { request: {}, addresses: [], previousPage: previousPage } }); // Get the request from the route state param
  const [addressSelected, SelectAddress] = useState(
    addressList[Object.keys(addressList)[0]]
  );
  const [selectedSecuredContent, setSelectedSecuredContent] = useState({});
  const [saved, setSave] = useState(
    Object.keys(addressList).length === 1 ? false : true
  );
  const [securedcontents, setSecuredcontents] = useState([]);

  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const processForm = () => {
    let updatedrequest = Object.create(Arequest);

    if(Arequest?.tags?.contenttype?.Value === "address_locales") {
      if (addressSelected) {
        updatedrequest.addressID = addressSelected.id;
      } else {
        console.log("Address not selected trying first address");
        updatedrequest.addressID = Object.keys(addressList)[0];
      }
    }
    if(Arequest?.tags?.contenttype?.Value !== "address_locales")
      updatedrequest.securedcontentID = document.getElementById('contentselect').value;
    
    if (updatedrequest.addressID !== "" || updatedrequest.securedcontentID !== "") {
      updatedrequest.status = "ACCEPTED";
      updatedrequest.requestType = Arequest.requestType;
      updatedrequest.id = Arequest.id;
      updatedrequest.tags = Arequest.tags;
      console.log(updatedrequest);
      UpdateRequest(updatedrequest)
        .then((response) => {
          setSave(true);
          //window.alert("Request Update Success!");
          setShowAlert(true);
          setAlertText("Request Update Success!");
        })
        .catch((error) => {
          //window.alert("Request Update Failed!");
          setShowAlert(true);
          setAlertText("Request Update Failed!");
          console.error(
            `Error updaing details for ID ${updatedrequest.id}:`,
            error
          );
        });
    } else {
      //window.alert("No address to select");
      setShowAlert(true);
      setAlertText("No address to select.");
    }
  };

  useEffect(() => {
    setSave(false)    
    if(Arequest?.tags?.contenttype?.Value !== "address_locales"){
      GetSecuredContents().then(resp => {
        setSecuredcontents(resp);
      }).catch(err => console.log('error fetching secured content', err)) 
      if(Arequest?.securedcontentID !== ""){
        GetSecuredContent(Arequest.securedcontentID).then((resp)=>{
          setSelectedSecuredContent(resp);
        }).catch(err => console.log("Error fetching secured content",err))
      }
    }
    
  }, [])

  const handleInputChange = (e) => {
    const { value } = e.target;
    SelectAddress(addressList[value]);
    Arequest.addressID = value;
    setSave(false);
  };

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="form-label py-2">
            <AiOutlineImport className="inline-icon" />
            Request Details
          </h1>
        </div>

        <div className="grid grid-cols-1">
          <p
            style={{ marginBottom: "20px" }}
            className="form-label"
          >
            You have a request to{" "}
            {Arequest?.requestType === "SHARE_ADDRESS" ? "Share address" : "Share content"} 
          </p>
          <form className="" id="address select">
            <label className="form-label" htmlFor="addressselect">
              Choose {contentTypeList.map((ele) => { if( Arequest?.tags?.contenttype?.Value === ele.value || selectedSecuredContent.tags?.contenttype?.Value === ele.value) return ele.name} )}
            </label>
            { Arequest?.tags?.contenttype?.Value === "address_locales" || selectedSecuredContent.tags?.contenttype?.Value === "address_locales" ?  Object.keys(addressList).length === 0 ? 
            <><p className="py-3">You don't have any address(es) yet. 
            <Link className="" to={{ pathname: "/AddAddressDetails" }} state={{request: Arequest, addresses: addressList,previousPage: "/process-request"}} >
              <button className="text-blue-500 bg-slate-333 hover:bg-555 hover:underline " title="Add New Address">
                <AiOutlineProfile className="inline-icon" /> Add New Address
              </button>
            </Link>  </p></>
            :<select defaultValue={`${Arequest?.addressID || addressSelected?.id || Object.keys(addressList)[0]}`} onChange={handleInputChange} className="form-input" name="addressselect" id="addressselect">
              {Object.keys(addressList || {}).map((addressId, index) => {
                const address = addressList[addressId];
                return (<option key={`${address.id}`} value={`${address.id}`}>{`${address.tags?.atag?.Name}`}</option>);
              })}
            </select> 
            : securedcontents?.length > 0 ? <select id="contentselect"  onChange={handleInputChange} className="form-input" >
            { securedcontents.filter(a => a.tags?.contenttype?.Value === Arequest?.tags?.contenttype?.Value || selectedSecuredContent.tags?.contenttype?.Value === a.tags?.contenttype?.Value ).sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sp, index) => {
               return <option key={sp.id} value={sp.id} selected={selectedSecuredContent.id ===sp.id || index === 0}>{sp.tags?.name?.Value || sp.tags?.title?.Value}</option> }) 
            } 
         </select>
            :<></>
            }
          </form>
          <form id="form-address-new" name="form-address-new">
              <label htmlFor="fromemail" className="form-label" >
                Request from Email
              </label>
              <p id="fromemail" name="fromemail" className=" px-2 py-2 form-input">
                {" "}
                {requestFrom ? Object.values(requestFrom).map((e) => {return e ? e : "" }) : ""}{" "}
              </p>
              <label className="form-label" htmlFor="email" >
                It was sent to Email
              </label>
              <p id="email" name="email" className="px-2 py-2 form-input">
                {" "}
                {Arequest?.toIndividualEmail || ""}{" "}
              </p>
              
          </form>
<section class="flex justify-start items-center space-x-4 py-2 ">
          <button style={{width: "fit-content",display: saved ? "none" : "block"}} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} form="address select" id="btn-update-request-submit" className="button-style positive-action">
            SAVE
          </button>
          <div>
            {!saved ? (
              <Link to={previousPage} className="button-style">
                <button >Cancel</button>
              </Link>
            ) : (
              <Link to={previousPage} className="button-style">
                <button >Close</button>
              </Link>
            )}
          </div>

          <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }}>
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
        
      </div>
      </div>
    </div>
  );
};

export default ProcessRequest;
