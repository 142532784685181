const countryList =  [
    { "name": "United States", "value": "United States" },
    { "name": "India", "value": "India" },
    { "name": "Australia", "value": "Australia" },
    { "name": "Canada", "value": "Canada" },
    { "name": "United Kingdom", "value": "United Kingdom" },
    { "name": "South Africa", "value": "South Africa" },
    { "name": "New Zealand", "value": "New Zealand" },
    { "name": "Ireland", "value": "Ireland" },
    { "name": "Singapore", "value": "Singapore" },
    { "name": "Malaysia", "value": "Malaysia" },
    { name: "France", value: "France" },
    { name: "Germany", value: "Germany"},
    { name: "Italy", value: "Italy"},
    { name: "Spain", value: "Spain"},
    { name: "Sweden", value: "Sweden"}
  ]

const address_locales = {
    "en-US": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Addressee", "id": "addressee", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "Zip Code", "id": "zipCode", "type": "alphanumeric" },
      { "label": "State", "id": "state", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "en-IN": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Addressee", "id": "addressee", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "State", "id": "state", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Pincode ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "en-CA": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "Province", "id": "state", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Postal Code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "en-AU": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "State/ Territory", "id": "state", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Postcode ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "en-GB": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Addressee", "id": "addressee", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "Locality", "id": "city", "type": "alphanumeric" },
      { "label": "Post town", "id": "state", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Postcode ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "en-SG": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "City Postal Code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "fr_FR": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "Postal code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "de_DE": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "Postal code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "it_IT": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "Postal Code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "Province", "id": "state", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "es_ES": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "Postal code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "sv_SE": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "Post code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ],
    "default": [
      { "label": "Name", "id": "name", "type": "alphanumeric" },
      { "label": "Street", "id": "street", "type": "alphanumeric" },
      { "label": "City", "id": "city", "type": "alphanumeric" },
      { "label": "State", "id": "state", "type": "alphanumeric" },
      { "label": "Country", "id": "country", "type": "select", "options": countryList },
      { "label": "Zip Code ", "id": "zipCode", "type": "alphanumeric" },
      { "label": "Phone", "id": "phone", "type": "alphanumeric" },
      { "label": "Email", "id": "email", "type": "alphanumeric" }
    ]
  };


  const countryLocaleList = [
    { name: "United States", value: "en-US" },
    { name: "India", value: "en-IN" },
    { name: "Australia", value: "en-AU" },
    { name: "Canada", value: "en-CA" },
    { name: "United Kingdom", value: "en-GB" },
    { name: "South Africa", value: "en-ZA" },
    { name: "New Zealand", value: "en-NZ" },
    { name: "Ireland", value: "en-IE" },
    { name: "Singapore", value: "en-SG" },
    { name: "Malaysia", value: "en-MY" },
    { name: "France", value: "fr_FR" },
    { name: "Germany", value: "de_DE"},
    { name: "Italy", value: "it_IT"},
    { name: "Spain", value: "es_ES"},
    { name: "Sweden", value: "sv_SE"}
    // Add more countries as needed
  ];
  const serviceCategoriesList = [
    {name: "Window Cleaning", value:"window-cleaning"},
    {name: "Plumber", value:"plumber"},
    {name: "Car Mechanic", value:"car-mechanic"},
    {name: "Carpenter", value:"carpenter"},
    {name: "Electrician", value:"electrician"},
    {name: "Painter", value:"painter"},
    {name: "Gardener", value:"gardener"},
    {name: "Software Services", value:"softwareservice"},
    {name: "SaaS", value:"saas"},
    {name: "Solution Provider", value:"solutionprovider"},
    {name: "Software Platform", value:"softwareplatform"},
    {name: "Financial Solutions", value:"financialsolution"},
    {name: "Human Resources", value:"humanresource"},
    {name: "Education", value:"education"},
    {name: "Event Management", value:"eventmanagement"},
    {name: "Others", value:"others"}
  ]
  const service_provider_profile = [
    {"label": "Name", "id": "name", "type": "alphanumeric"},
    {"label": "Describe your services", "id": "description", "type": "textarea"},
    {"label": "Categories", "id": "categories", "type": "select", "options": serviceCategoriesList },
    {"label": "Website", "id": "website", "type": "alphanumeric"},
    {"label": "Logo URL", "id": "logourl", "type": "alphanumeric"},
    {"label": "Would you like to connect JustWhere via API?", "id": "apiconnect", "type": "checkbox"},
    {"label": "API Domain Name", "id": "apidomain", type:"alphanumeric"}
  ]
  
  const private_service_provider_profile = [
    {"label": "Name", "id": "name", "type": "alphanumeric"},
    {"label": "Describe your services", "id": "description", "type": "textarea"},
    {"label": "Categories", "id": "categories", "type": "select", "options": serviceCategoriesList },
    {"label": "Website", "id": "website", "type": "alphanumeric"}
  ]
  
   
  const insurance_details = [
    {"label": "Name", "id": "name", "type": "alphanumeric"},
    {"label": "Policy No.", "id": "policyno", "type": "alphanumeric"},
    {"label": "Effective Date", "id": "effectivedate", "type": "alphanumeric"},
    {"label": "Expiry Date", "id": "expirydate", "type": "alphanumeric"},
    {"label": "Amount", "id": "amount", "type": "alphanumeric"}
  ]
  
  const employee_records = [
    {"label": "Name", "id": "name", "type": "alphanumeric"},
    {"label": "Employee Id", "id": "employeeId", "type": "alphanumeric"},
    {"label": "Hire Date", "id": "hiredate", "type": "alphanumeric"},
    {"label": "SSN", "id": "employeessn", "type": "alphanumeric"},
    {"label": "Date of Birth", "id": "birthdate", "type": "alphanumeric"}
  ]

  const notes = [
    {"label": "Title", "id": "title", "type": "alphanumeric"},
    {"label": "Description", "id": "description", "type": "textarea"}
  ]

  const contentTypeList = [
    {name: "Addresses", value:"address_locales", fields: address_locales},
    {name: "Employee Records", value:"employee_records", fields: employee_records},
    {name: "Insurance Records", value:"insurance_details", fields: insurance_details},
    {name: "Notes", value: "notes", fields: notes}
  ]

  
 

export {address_locales, countryLocaleList,  service_provider_profile,private_service_provider_profile, serviceCategoriesList, contentTypeList, employee_records, insurance_details, notes};
