import { BsFillTrash3Fill } from "react-icons/bs";
import ServiceProviderModal from "./ServiceProviderModal";
import CopyToClipboard from './CopyToClipboard';
import DeleteModal from './DeleteModal';
import React, { useEffect, useState } from 'react';
import { getInfo, FetchIndividual, GetServiceProvider, DeleteServiceProvider } from "../utilities/calls";
import { AiOutlineSearch, AiOutlineProfile } from "react-icons/ai";


export default function PrivateServiceProviders() {
    const [serviceProviderModal, setServiceProviderModal] = useState(false)
    const [serviceProviders, setServiceProviders] = useState([]);
    const [serviceProviderList, setServiceProviderList] = useState([]);
    const [showDeleteDlg, setShowDeleteDlg] = useState();
    const [currSpId, setCurrSpId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [search, setSearch] = useState("");
    const [selectedSP, setSelectedSP] = useState({});

    useEffect(() => {
        getPrivateServiceProviders();
    }, []);

    const getPrivateServiceProviders = () => {
        const individualid = getInfo("IndividualID");
        if (individualid === null) return;
        FetchIndividual(individualid)
            .then((individualData) => {
                setServiceProviderList([]);
                setServiceProviders(individualData?.privateserviceproviders?.map((psp) => { return psp.id }) || []);
            })
            .catch((error) => {
                console.error("Error fetching individual:", error);
                setShowAlert(true);
                setAlertText('Error loading data, please try again')
            });
    }

    useEffect(() => {
        serviceProviders.forEach((sp) => {
           if (serviceProviderList.findIndex((s) => s.id === sp) === -1) {
                GetServiceProvider(sp, true).then((spDetails) => {
                    (
                        setServiceProviderList((serviceProviderList) => [...serviceProviderList, spDetails]));
                });
            }
        });
    }, [serviceProviders]);

    const handleDeleteClick = (spid) => {
        setShowDeleteDlg(true);
        setCurrSpId(spid);
    }

    const deletePSP = () => {
        setShowDeleteDlg(false);

        DeleteServiceProvider(currSpId, true).then(() => {
            setShowAlert(true);
            setAlertText("Private Service Provider was successfully deleted.");
            getPrivateServiceProviders();
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        });
    }

    const editSP = (spid) => {
        const sp = serviceProviderList.find((s) => s.id === spid) || {};
        setSelectedSP(sp);
        setServiceProviderModal(true);
    }

    const addPSP = () => {
        setSelectedSP({});
        setServiceProviderModal(true);
    }

    const getSelectedSP = () => {
        return selectedSP;
    }

    const handleInputChange = (event) => {
        setSearch(event.target.value);
    };

    return (

        <div className='space-y-3 px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center'>
            {showAlert ? (
                <div
                    class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                    role="alert"
                    style={{ display: showAlert ? "block" : "none" }}
                >
                    <span class="text-sm">{alertText} </span>
                </div>
            ) : (
                <></>
            )}
            <DeleteModal confirmationText="Are you certain you wish to delete this service provider?" deleteLabel="Delete" onDeleteFn={deletePSP} onCancelFn={() => { setShowDeleteDlg(false) }} show={showDeleteDlg} />

            <h1 className="bold-text-input">Private Service Providers</h1>
            {serviceProviders?.length > 0 ? <></>: <p className="text-small py-3">Save your physician, insurance, mechanic, emergency contact details to easily get in touch. It is visibile only to you</p>}

            <button className="button-style hover:bg-555 mb-2" onClick={addPSP}>
                <AiOutlineProfile className='inline-icon' />
                Add Service Provider
            </button>

            <div className='p-5 border border-gray-700 mt-2'>
                <ul className='w-full divide-y divide-gray-700'>
                    {serviceProviders?.length > 0 ? serviceProviderList.sort((a, b) => { return a.tags?.name?.Value?.toLowerCase().localeCompare(b.tags?.name?.Value?.toLowerCase()) }).map((sp, index) => {
                        return <li class="pb-3 sm:pb-4">

                            <div class="flex items-center space-x-4 rtl:space-x-reverse">

                                <div class="flex-1">
                                    <p class="text-base font-semibold truncate text-blue-400">
                                        <button onClick={() => editSP(sp.id)}>

                                            {`${sp.tags?.name?.Value || "No Name"}`}
                                        </button>
                                    </p>

                                </div>
                                
                                <div class="inline-flex items-center text-white">
                                    <button formMethod="dialog" formTarget="top" id="deleteSP" onClick={() => handleDeleteClick(sp.id)} className="bg-slate-333 py-1 hover:bg-555">
                                        <BsFillTrash3Fill title="Delete" className="inline-icon" />
                                    </button>
                                </div>
                            </div>
                            <p class="text-sm text-gray-400">
                                <span id={"sp_guid_" + index}>{sp.id}</span><CopyToClipboard elId={"sp_guid_" + index} />
                            </p>
                        </li>

                    })

                        : <li>No Service Provider found.</li>
                    }
                </ul>
            </div>
            {
                serviceProviderModal && <ServiceProviderModal setServiceProviderModal={setServiceProviderModal} serviceProviders={serviceProviders} sp={getSelectedSP} onUpdate={getPrivateServiceProviders} />
            }


        </div>
    )

}