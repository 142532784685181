import React, { useEffect, useLayoutEffect, useState } from "react";
import { GetAddress, GetAccount, GetAccounts, GetSharesWithServiceProvider, GetServiceProvider, getInfo, GetBeneficiary, GetSharedSecuredContent, GetSharesWith } from "../utilities/calls";
import Login from "./Login";
import { useNavigate } from "react-router-dom";
import ContentBox from "./SecuredContentBox";
import AddressBox from "./AddressBox";
const ServiceProviderShares = () => {
    const [addresses, setAddresses] = useState([]);
    const [shares, setShares] = useState({});
    const [sharesBen, setSharesBen] = useState({});
    const [userId, setUserId] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertText, setAlertText] = useState();
    const [serviceProviderList, setServiceProviderList] = useState({});
    const [beneficiaryList, setBeneficiaryList] = useState([]);
    const [sharesWithBen, setSharesWithBen] = useState([]);
    const [benList, setBenList] = useState({});
    const [securedContents, setSecuredContents] = useState([]);
    const [state, setState] = useState(0);
    const navigate = useNavigate()

    useLayoutEffect(() => {
        setUserId(getInfo("UserID"));
    }, []);

    useLayoutEffect(() => {

        GetAccounts().then((response) => {
            if (Object.keys(response).length === 0) if (state !== 2) setState(1);
            Object.keys(response).forEach((key, index) => {
                GetAccount(key).then((accDetails) => {
                    if (Object.keys(accDetails.ServiceProviders).length === 0 && Object.keys(accDetails.Beneficiaries).length === 0) if (state !== 2) setState(1);
                    Object.keys(accDetails.Beneficiaries).forEach((k, i) => {

                        GetBeneficiary(k).then((benDetails) => {
                            if (state !== 2) setState(1);
                            (
                                setBeneficiaryList((benList) => ([...benList, benDetails])))
                        }).catch(e => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })

                        GetSharesWith(k).then((share) => {
                            if (share.length > 0) {
                                setSharesBen((sharesBen) => ({ ...sharesBen, [share[0].beneficiaryID]: share }));
                                Promise.all(
                                    share.filter(d => d.securedcontentID === "").map(async (el) => {
                                        try {
                                            const benid = el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? el.serviceProviderID : el.beneficiaryID;
                                            const result = await GetAddress(el.addressID, benid, el.token, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;

                                        } catch (err) {
                                            console.error("Error fetching addresses:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                ).then((addrs) => {
                                    setAddresses((addresses) => [...addresses, ...addrs]);
                                    if (state !== 2) setState(1);
                                }).catch((e) => {
                                    console.log(e);
                                    setShowAlert(true);
                                    setAlertText("Error fetching data");
                                    setState(2);
                                });
                                Promise.all(
                                    share.filter(d => d.securedcontentID !== "").map(async (el) => {
                                        try {
                                            const benid = el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? el.serviceProviderID : el.beneficiaryID;
                                            const result = await GetSharedSecuredContent(el.securedcontentID, el.token, benid, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;

                                        } catch (err) {
                                            console.error("Error fetching secured content:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                ).then((content) => {
                                    setSecuredContents((securedContents) => [...securedContents, ...content]);
                                    if (state !== 2) setState(1)
                                }).catch((e) => {
                                    console.log(e);
                                    setShowAlert(true);
                                    setAlertText("Error fetching data");
                                    setState(2);
                                });

                            }
                        }).catch((e) => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })
                    })

                    Object.keys(accDetails.ServiceProviders).forEach((k, i) => {

                        GetServiceProvider(k).then((spDetails) => {
                            if (state !== 2) setState(1);
                            setServiceProviderList((serviceProviderList) => ({ ...serviceProviderList, [spDetails.id]: spDetails }));
                        }).catch(e => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })
                        GetSharesWithServiceProvider(k).then((share) => {
                            if (share.length > 0) {

                                let ben = share.filter((d) => d.beneficiaryID?.localeCompare("00000000-0000-0000-0000-000000000000") !== 0);
                                setSharesWithBen((sharesWithBen) => [...sharesWithBen, ...ben]);

                                setShares((shares) => ({ ...shares, [share[0].serviceProviderID]: share }));
                                Promise.all(
                                    share.filter(d => d.securedcontentID === "").map(async (el) => {
                                        try {
                                            const benid = el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? el.serviceProviderID : el.beneficiaryID;
                                            const result = await GetAddress(el.addressID, benid, el.token, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;

                                        } catch (err) {
                                            console.error("Error fetching addresses:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                ).then((addrs) => {
                                    setAddresses((addresses) => [...addresses, ...addrs]);
                                    if (state !== 2) setState(1);
                                }).catch((e) => {
                                    console.log(e);
                                    setShowAlert(true);
                                    setAlertText("Error fetching data");
                                    setState(2);
                                });
                                Promise.all(
                                    share.filter(d => d.securedcontentID !== "").map(async (el) => {
                                        try {
                                            const benid = el.beneficiaryID.localeCompare("00000000-0000-0000-0000-000000000000") === 0 ? el.serviceProviderID : el.beneficiaryID;
                                            const result = await GetSharedSecuredContent(el.securedcontentID, el.token, benid, el.individualID);
                                            result.userEmail = decodeURIComponent(
                                                el.tags.UserEmail ? el.tags.UserEmail.Value : ""
                                            );
                                            result.SharedWithEmail = decodeURIComponent(
                                                el.tags.SharedWithEmail ? el.tags.SharedWithEmail.Value : ""
                                            );
                                            return result;

                                        } catch (err) {
                                            console.error("Error fetching secured content:", err);
                                            setShowAlert(true);
                                            setAlertText("Error fetching data");
                                        }
                                    })
                                ).then((content) => {
                                    setSecuredContents((securedContents) => [...securedContents, ...content]);
                                    if (state !== 2) setState(1);
                                }).catch((e) => {
                                    console.log(e);
                                    setShowAlert(true);
                                    setAlertText("Error fetching data");
                                    setState(2);
                                });
                            }

                        }).catch(e => {
                            console.log(e);
                            setShowAlert(true);
                            setAlertText("Error fetching data");
                            setState(2);
                        })


                    })
                })
            })
        }).catch((error) => {
            console.error("Error fetching accounts:", error);
            setShowAlert(true);
            setAlertText("Error fetching data from server. Please try again!");
            setState(2);
        });
    }, []);


    useEffect(() => {
        let benIds = []
        sharesWithBen.map((req) => {
            if (!benIds.includes(req.beneficiaryID)) {
                benIds.push(req.beneficiaryID);
                GetBeneficiary(req.beneficiaryID).then((el) => setBenList((benList) => ({ ...benList, [req.beneficiaryID]: el }))).catch((err) => console.log(err))
            }
        })
    }, [sharesWithBen])

    const showSPs = () => {
        if(Object.keys(shares).length > 0 ){
            return Object.keys(shares).map((k) => {
                return <div>
                    <h1 className="p-5 bold-text-input">Content Shared with {serviceProviderList[k]?.tags?.name?.Value}</h1>
                    <div class=" bg-gray  ">
                        <div class="p-5 max-w-full">
                        <div className="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">

                                {shares[k].map((s) => {
                                    var bens = sharesWithBen.filter((el) => el.serviceProviderID === s.serviceProviderID && (el.addressID === s.addressID && el.securedcontentID === s.securedcontentID))
                                    var from = decodeURIComponent(
                                        s?.tags?.UserEmail ? s.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        s?.tags?.SharedWithEmail ? s.tags.SharedWithEmail.Value : ""
                                    );
                                    var content = {}; 
                                    var address = {};
                                    if(s.securedcontentID)
                                     content = securedContents.find(ele => ele?.id === s.securedcontentID)
                                    else
                                     address = addresses.find((ele) => s.addressID === ele?.id)
                                    return  s.securedcontentID ? <ContentBox contenttype={content?.tags?.contenttype?.Value}  created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""} content={content} from={from} to={to} requestId={s?.tags?.RequestId?.Value} benList={beneficiaryList} ben={bens} isBusiness={true}/>: 
                                            <AddressBox created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""}  from={from} to={to} address={address} requestId={s?.tags?.RequestId?.Value} benList={beneficiaryList} ben={bens} isBusiness={true}></AddressBox>
                                                                                    
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            }) 
        } else return <></>
    }

    const showBens = () => {
        if(Object.keys(sharesBen).length > 0 ){
            return Object.keys(sharesBen).map((k) => {
                return <div> 
                    <h1 className="p-5 bold-text-input">Content Shared with {beneficiaryList.find(b => b.id === k)?.tags?.name?.Value}</h1>
                    <div class=" bg-gray  ">
                        <div class="p-5 max-w-full">
                        <div className="grid grid-flow-row px-3  gap-4 grid-cols-1 md:grid-cols-3">
                                        
                                {sharesBen[k].map((s) => {
                                    var content = {}; 
                                    var address = {};
                                    if(s.securedcontentID)
                                        content = securedContents.find(ele => ele?.id === s.securedcontentID)
                                    else
                                        address = addresses.find((ele) => s.addressID === ele?.id)
                                    var from = decodeURIComponent(
                                        s?.tags?.UserEmail ? s.tags.UserEmail.Value : ""
                                    )
                                    var to = decodeURIComponent(
                                        s?.tags?.SharedWithEmail ? s.tags.SharedWithEmail.Value : ""
                                    );
                                    return s.securedcontentID ? <ContentBox contenttype={content?.tags?.contenttype?.Value}  created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""} content={content} from={from} to={to} /> :
                                                <AddressBox created={s?.created ? s.created : ""} updated={s?.updated ? s.updated : ""}  from={from} to={to} address={address} ></AddressBox>
                                                
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            })
        } else return <></>
    }


    return userId ? (

        <div className="px-1 md:px-4 py-3 max-w-screen-xl mx-auto items-center">
            <div
                class="  bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
                role="alert"
                style={{ display: showAlert ? "block" : "none" }}
            >
                <span class="text-sm">{alertText} </span>
            </div>
            {
                state === 0 ? "Loading..." : Object.keys(shares).length === 0 && Object.keys(sharesBen).length === 0 ? (<div className="w-full h-full p-2 flex items-start justify-start text-blue-300">
                    <p className="text-start">It looks like you don't have any shared content yet. Keep adding and updating your profile so people can start sharing with you!</p>
                </div>) : <>  {showSPs()} {showBens()}</>

            }
        </div>

    ) : (
        <Login />
    );
};


export default ServiceProviderShares;
